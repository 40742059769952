/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useEffect, useState } from 'react'
import axios from 'axios'
import { DialogWrapper, LinkButton, Loader, StepContext } from '@everybyte-io/components'
import SweetAlert from 'react-bootstrap-sweetalert'
import { getQueryStringValue, parentComms } from '../functions'
import { JOURNEY_SERVER_URI, PLAID_SERVER_URI } from '../consts'
import { SessionData } from '../@types'
import logo from '../assets/Everybyte_logo.png'
import OTP from '../components/IdentifyJourney/OTP'
import Authorize from '../components/IdentifyJourney/Authorize'
import Verify from '../components/IdentifyJourney/Verify'

const Identify = (): JSX.Element => {
  const sessionId = getQueryStringValue('sessionId')

  const [loading, setLoading] = useState(false)
  const [displayError, setDisplayError] = useState<false | string>(false)

  const [authState, setAuthState] = useState<string>('')
  const [accessToken, setAccessToken] = useState<string>('')
  const [sessionData, setSessionData] = useState<SessionData | null>(null)

  const getSessionData = async (): Promise<void> => {
    setLoading(true)

    try {
      const {
        data: {
          session,
        },
      } = await axios.get(`${JOURNEY_SERVER_URI}/${sessionId}/public`)
  
      setSessionData(session)
    } catch (error) {
      console.error(error)
    }

    setLoading(false)
  }

  const updateSessionData = async (body: Record<string, any> = {}, token?: string): Promise<void> => {
    try {
      const {
        data: {
          session,
        },
      } = await axios.patch(
        `${JOURNEY_SERVER_URI}/${sessionId}`,
        body,
        {
          headers: {
            Authorization: `Bearer ${token ?? accessToken}`,
          },
        },
      )

      setSessionData(session)
    } catch (error) {
      console.error(error)
    }
  }

  const getAuthState = async (token?: string): Promise<void> => {
    try {
      const {
        data: {
          authState,
        },
      } = await axios.post(
        `${PLAID_SERVER_URI}/get-auth-state`,
        {
          products: ['identity', 'transactions'],
        },
        {
          headers: {
            Authorization: `Bearer ${token ?? accessToken}`,
          },
        },
      )

      setAuthState(authState)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (sessionId && !sessionData) {
      getSessionData()
    } else if (!sessionId) {
      console.warn('No session information was provided, please verify query: ?sessionId={SESSION_ID}')
      setDisplayError('No session information was provided, check logs for more information.')
    } else if (sessionData && !sessionData.active) {
      console.warn('This session is inactive, please verify the session ID')
      setDisplayError('This session is inactive, check logs for more information.')
    }
  }, [sessionId, sessionData]) // eslint-disable-line

  const otpCallback = async (token: string, auth0UserId: string): Promise<void> => {
    setAccessToken(token)
    await updateSessionData({ auth0UserId }, token)
    await getAuthState(token)
  }

  const onClickClose = (): void => {
    if (window.confirm('Are you sure you want to abort this session?')) {
      parentComms('abort')
      parentComms( 'close')
    }
  }

  const onClickCloseError = (): void => {
    parentComms( 'close')
  }

  return (
    <DialogWrapper
      numberOfPhases={3}
      preventBackAtSteps={[2, 3]}
      onClickClose={onClickClose}
      Image={() => <img src={logo} alt='Everybyte' height='42' />}
    >
      <StepContext.Consumer>
        {({ step, increment }): JSX.Element | null => (
          <Fragment>
            {displayError ? (
              <SweetAlert
                error
                title='Error!'
                onConfirm={onClickCloseError}
                customButtons={<LinkButton label='Confirm' onClick={onClickCloseError} />}
              >
                {displayError}
              </SweetAlert>
            ) : loading ? (
              <div style={{ width: '100%', height: '100%', display: 'grid', placeItems: 'center' }}>
                <Loader label='Getting session information...' />
              </div>
            ) : sessionData ? (
              step === 1 ? (
                <OTP
                  nextStep={increment}
                  otpCallback={otpCallback}
                  sessionData={sessionData}
                />
              ) : step === 2 ? (
                <Authorize
                  nextStep={increment}
                  authState={authState}
                  accessToken={accessToken}
                />
              ) : step === 3 ? (
                <Verify
                  sessionId={sessionId}
                  accessToken={accessToken}
                />
              ) : null
            ) : null}
          </Fragment>
        )}
      </StepContext.Consumer>
    </DialogWrapper>
  )
}

export default Identify
