import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { PlaidLink, Loader } from '@everybyte-io/components'
import { PLAID_SERVER_URI, PLAID_WEBHOOK } from '../../../consts'

interface Props {
  accessToken: string
  callback: () => void
}

export default function Plaid({ accessToken, callback }: Props): JSX.Element {
  const [loading, setLoading] = useState(false)
  const [loadingMessage, setLoadingMessage] = useState('')
  const [linkToken, setLinkToken] = useState('')

  const getLinkToken = async (): Promise<void> => {
    setLoading(true)
    setLoadingMessage('Preparing Plaid Link...')

    try {
      const {
        data: {
          linkToken: token,
        },
      } = await axios.post(
        `${PLAID_SERVER_URI}/get-link-token`,
        {
          clientName: 'Everybyte',
          products: ['identity', 'transactions'],
          countryCodes: ['US'],
          language: 'en',
          webhook: PLAID_WEBHOOK,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      )

      setLinkToken(token)
    } catch (error) {
      console.error(error)
    }

    setLoading(false)
  }

  const postPlaidData = async (publicToken: string): Promise<void> => {
    setLoading(true)
    setLoadingMessage('Connecting accounts...')

    try {
      await axios.post(
        `${PLAID_SERVER_URI}/post-public-token`,
        {
          publicToken,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      )
      
      callback()
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!linkToken) getLinkToken()
  }, [linkToken]) // eslint-disable-line

  return (
    <>
      {loading ? (
        <div style={{ width: '100%', height: '100%', display: 'grid', placeItems: 'center' }}>
          <Loader label={loadingMessage} />
        </div>
      ) : linkToken ? (
        <PlaidLink linkToken={linkToken} onExit={() => setLinkToken('')} callback={postPlaidData} />
      ) : null}
    </>
  )
}
