import React from 'react'
import axios from 'axios'
import { PLAID_SERVER_URI } from '../../../consts'
import VerifyTransaction from './VerifyTransaction'
import Plaid from './Plaid'

interface Props {
  nextStep: () => void
  authState: string
  accessToken: string
}

const Authorize = ({
  nextStep,
  authState,
  accessToken,
}: Props): JSX.Element => {
  const createIdentityItem = async (): Promise<void> => {
    try {
      await axios.post(
        `${PLAID_SERVER_URI}/create-identity-item`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      )
    } catch (error) {
      console.error(error)
    }
  }

  if (authState === 'NEW_ITEM') {
    return (
      <Plaid
        accessToken={accessToken}
        callback={async () => {
          await createIdentityItem()
          nextStep()
        }}
      />
    )
  }

  return (
    <VerifyTransaction
      accessToken={accessToken}
      callback={nextStep}
    />
  )
}

export default Authorize
