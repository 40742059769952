import React, { useEffect } from 'react'
import axios from 'axios'
import { Loader } from '@everybyte-io/components'
import { JOURNEY_SERVER_URI } from '../../../consts'
import { ComparedResult } from '../../../@types'

interface Props {
  sessionId: string
  accessToken: string
  setComparedResult: React.Dispatch<React.SetStateAction<ComparedResult | null>>
  setIsSuccess: React.Dispatch<React.SetStateAction<boolean>>
  setNotSuccessMessage: React.Dispatch<React.SetStateAction<string>>
}

export default function VerifyIdentity({
  sessionId,
  accessToken,
  setComparedResult,
  setIsSuccess,
  setNotSuccessMessage,
}: Props): JSX.Element {
  const verifyIdentity = async (): Promise<void> => {
    try {
      const {
        data,
      } = await axios.post(
        `${JOURNEY_SERVER_URI}/verify-identity`,
        {
          sessionId,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      )

      setComparedResult(data)
      setIsSuccess(true) 
    } catch (error: any) { // eslint-disable-line
      console.error(error)
      setNotSuccessMessage(error?.response?.message ?? error?.message ?? '-')
    }
  }

  useEffect(() => {
    verifyIdentity()
  }, []) // eslint-disable-line

  return (
    <div style={{ width: '100%', height: '100%', display: 'grid', placeItems: 'center' }}>
      <Loader label='Verifying identity...' />
    </div>
  )
}
