import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import IdentityJourney from './pages/Identify'

const App = (): JSX.Element => {
  return (
    <div>
      <Switch>
        <Route exact path='/' component={IdentityJourney} />
        <Route>
          <Redirect to='/' />
        </Route>
      </Switch>
    </div>
  )
}

export default App
