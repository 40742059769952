import React, { useState } from 'react'
import { ComparedResult } from '../../../@types'
import Finished from './Finished'
import VerifyIdentity from './VerifyIdentity'

interface Props {
  sessionId: string
  accessToken: string
}

const Verify = ({ sessionId, accessToken }: Props): JSX.Element => {
  const [isSuccess, setIsSuccess] = useState<boolean>(false)
  const [notSuccessMessage, setNotSuccessMessage] = useState<string>('')
  const [comparedResult, setComparedResult] = useState<ComparedResult | null>(null)

  return isSuccess || notSuccessMessage ? (
    <Finished
      isSuccess={isSuccess}
      notSuccessMessage={notSuccessMessage}
      comparedResult={comparedResult}
    />
  ) : (
    <VerifyIdentity
      sessionId={sessionId}
      accessToken={accessToken}
      setComparedResult={setComparedResult}
      setIsSuccess={setIsSuccess}
      setNotSuccessMessage={setNotSuccessMessage}
    />
  )
}

export default Verify
