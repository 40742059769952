import React from 'react'
import { LinkButton } from '@everybyte-io/components'
import SweetAlert from 'react-bootstrap-sweetalert'
import { parentComms } from '../../../functions'
import { ComparedResult } from '../../../@types'

interface Props {
  isSuccess: boolean
  notSuccessMessage: string
  comparedResult: ComparedResult | null
}

export default function Finished({ isSuccess, notSuccessMessage, comparedResult }: Props): JSX.Element {
  const clickConfirm = (): void => {
    if (isSuccess) {
      parentComms('success', comparedResult ?? {})
    } else {
      parentComms('fail', { error: notSuccessMessage })
    }
    parentComms('close')
  }

  if (isSuccess) {
    return (
      <SweetAlert
        success
        title='Success!'
        onConfirm={clickConfirm}
        customButtons={<LinkButton label='Confirm' onClick={clickConfirm} />}
      >
        Process completed succesfully!
      </SweetAlert>
    )
  }

  return (
    <SweetAlert
      error
      title='Failed!'
      onConfirm={clickConfirm}
      customButtons={<LinkButton label='Confirm' onClick={clickConfirm} />}
    >
      The process failed!
    </SweetAlert>
  )
}
