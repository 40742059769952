import React, { useEffect, useState } from 'react'
import axios from 'axios'
import axiosRetry from 'axios-retry'
import { makeStyles } from '@mui/styles'
import { FormHelperText, Theme, Typography } from '@mui/material'
import { AccountBalance, AccountBalanceWallet, Event, AttachMoney } from '@mui/icons-material'
import { Button, Card, Input, Loader } from '@everybyte-io/components'
import { PLAID_SERVER_URI } from '../../../consts'
import { TransactionData } from '../../../@types'

const useStyles = makeStyles(
  ({ spacing }: Theme) => ({
    root: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      textAlign: 'center',
      padding: spacing(2),
      paddingTop: 25,
    },
    text: {
      fontSize: '0.9rem !important',
    },
    buttonContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
  }),
  { name: 'VerifyTransaction' },
)

interface Props {
  accessToken: string
  callback: () => void
}

const VerifyTransaction = ({ accessToken, callback }: Props): JSX.Element => {
  const classes = useStyles()

  const [loading, setLoading] = useState<boolean>(false)
  const [loadingMessage, setLoadingMessage] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<string>('')

  const [transaction, setTransaction] = useState<TransactionData | null>(null)
  const [amount, setAmount] = useState<string>('')

  const createTransactionItem = async (): Promise<void> => {
    setLoading(true)
    setLoadingMessage('Getting recent transaction information...')

    try {
      const axiosInstance = axios.create()

      axiosRetry(axiosInstance, { retryDelay: axiosRetry.exponentialDelay, retries: 10 })

      const {
        data,
      } = await axiosInstance.post(
        `${PLAID_SERVER_URI}/create-transaction-item`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      )
  
      setTransaction(data)
    } catch (error) {
      console.error(error)
    }

    setLoading(false)
  }

  const verifyTransactionAmount = async (): Promise<void> => {
    if (!amount) {
      return setErrorMessage('Incorrect amount, please try again')
    }

    setLoading(true)
    setLoadingMessage('Verifying transaction amount...')
    setErrorMessage('')

    try {
      const {
        data: {
          verified,
        },
      } = await axios.post(
        `${PLAID_SERVER_URI}/verify-transaction-amount`,
        {
          transactionId: transaction?.transactionId,
          amount,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      )

      if (verified) {
        callback()
      } else {
        setErrorMessage('Incorrect amount, please try again')
        setLoading(false)
      } 
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!transaction) createTransactionItem()
  }, [transaction]) // eslint-disable-line

  const transactionInfoCard = [
    {
      key: 'Name:',
      value: transaction?.merchantName ?? '-',
      icon: () => <AccountBalance />,
    },
    {
      key: 'Date:',
      value: transaction?.authorizedDate ?? '-',
      icon: () => <Event />,
    },
    {
      key: 'Currency:',
      value: transaction?.currency ?? '-',
      icon: () => <AccountBalanceWallet />,
    },
  ]

  return (
    <div className={classes.root}>
      <Typography align='center' className={classes.text}>
        Please verify yourself by answering the following security question:
      </Typography>
        
      <Card label='Transaction information' data={transactionInfoCard} backgroundColor='#dce5ff' />
        
      <div className={classes.buttonContainer}>
        <Typography align='center' className={classes.text}>
          What was the exact amount you&apos;ve spent on the transaction displayed above?
        </Typography>
        <Input
          value={amount}
          setValue={setAmount}
          type='number'
          label='Transaction amount'
          placeholder='6.33'
          icon={AttachMoney}
        />
        {errorMessage ? (
          <FormHelperText error sx={{ display: 'flex', justifyContent: 'center' }}>
            {errorMessage}
          </FormHelperText>
        ) : null}
      </div>

      <div className={classes.buttonContainer}>
        {loading ? (
          <Loader label={loadingMessage} />
        ) : (
          <Button label='Continue' invert textAlign='center' onClick={verifyTransactionAmount} />
        )}
      </div>
    </div>
  )
}

export default VerifyTransaction
