import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  palette: {
    primary: {
      main: 'rgb(0, 0, 0)',
    },
    background: {
      default: 'rgba(245, 246, 250, 0.4)',
    },
  },
  typography: {
    fontFamily: ['Cern', 'Helvetica', 'Arial', 'sans-serif'].join(','),
  },
})

export default theme
