/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import axios from 'axios'
import { ProcessOTP } from '@everybyte-io/components'
import otpImage from '../../assets/icons/otp3.jpeg'
import { AUTH_SERVER_URI } from '../../consts'
import { SessionData } from '../../@types'

interface Props {
  nextStep: () => void
  otpCallback: (accessToken: string, auth0UserId: string) => Promise<void>
  sessionData: SessionData | null
}

const SCOPE = 'create:plaid-link update:plaid-link create:plaid-item read:plaid-item update:identities'

const OTP = ({
  nextStep,
  otpCallback,
  sessionData,
}: Props): JSX.Element => {
  const [auth0UserId, setAuth0UserId] = useState<string>('')

  const sendOtp = async (phone: string, cb: (error?: string) => void): Promise<void> => {
    try {
      const {
        data: {
          auth0UserId: id,
        },
      } = await axios.post(
        `${AUTH_SERVER_URI}/send-otp`,
        {
          scope: SCOPE,
          phoneNumber: phone.indexOf('+') === 0 ? phone : `+${phone}`,
        },
      )

      setAuth0UserId(id)
      cb()
    } catch (error) {
      console.error(error)
      cb('Invalid phone number')
    }
  }

  const verifyOtp = async (phone: string, otp: string, cb: (error?: string) => void): Promise<void> => {
    try {
      const {
        data: {
          accessToken,
        },
      } = await axios.post(
        `${AUTH_SERVER_URI}/verify-otp`,
        {
          scope: SCOPE,
          phoneNumber: phone.indexOf('+') === 0 ? phone : `+${phone}`,
          otp,
        },
      )

      await otpCallback(accessToken, auth0UserId)
      cb()
    } catch (error) {
      console.error(error)
      cb('Invalid code')
    }
  }

  return (
    <ProcessOTP
      nextStep={nextStep}
      requirePhone={false}
      defaultCountry='us'
      sendOtp={sendOtp}
      verifyOtp={verifyOtp}
      forcePhone={sessionData?.phone ?? ''}
      imageSrc={otpImage}
    />
  )
}

export default OTP
